import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSettings } from 'src/app/models/settings';
import { ApisService } from 'src/app/services/apis.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  setting :DataSettings
  loading = true;
  pending :boolean;
  data : FormData = new FormData;
  constructor(private http :ApisService ,private router: Router) {
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }

  ngOnInit(): void {
    this.http.getSettings().subscribe(response =>{
      this.loading = false
      this.setting = response.data;
      this.pending = response.data.pending == 1
    })
  }

  
  selectItem(e , key :string){
    if(this.data.has(key))
      this.data.set(key , e ? "1" : "0");
    else
      this.data.append(key , e ? "1" : "0") ;
  }
  SaveSetting(){
    this.http.sendSettings(this.data).subscribe(response =>{
      if(response.status == 1){

        Swal.fire(
          'Success'
        )
      }
      else{
        Swal.fire(
          'Cancelled',
        'Your imaginary file is safe :)',
        'error'
        )
      }
    })
  }

}
