  <!-- ========== Left Sidebar Start ========== -->
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
        <!-- LOGO -->
        <div class="topbar-left">
            <a routerLink="/users" class="logo">
                <img src="../../../assets/images/logo.png" width="90%">
            </a>
        </div>
        <!-- User box -->
        <div class="user-box">
            <div class="user-img">
                <img src="assets/images/profile.png" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">
            </div>
            <h5><a href="#">{{userName}}</a> </h5>
            <p class="text-muted">Admin Head</p>
        </div>

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <ul class="metismenu" id="side-menu">
                <!-- <li>
                    <a routerLink="/users">
                        <i class="fi-air-play"></i> <span> Dashboard </span>
                    </a>
                </li> -->
                <li>
                    <a routerLink="/users" routerLinkActive="active">
                        <i class="mdi mdi-account"></i> <span> Users </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/settings" routerLinkActive="active">
                        <i class="fi-cog "></i> <span> Settings </span>
                    </a>
                </li>
            </ul>

        </div>
        <!-- Sidebar -->
        <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->